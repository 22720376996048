<template>
  <b-navbar class="fixed-top" variant="faded" type="light" fixed="top" toggleable="lg">
    <div class="container">
        <b-navbar-brand tag="h1" class="mb-0">
          <router-link :to="{ name: 'ProductTypeSelect' }">
            <img width="120" src="../_theme/images/logo.png">
          </router-link>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto d-flex align-items-center">
            <div v-if="user" class="user text-right mr-4">
              <p class="m-0">Prihlásený používateľ: {{ user.name + ' ' + user.surname }}</p>
              <b-link to="/ucet/nastavenie" class="mr-3">Nastavenie účtu</b-link>
              <b-link @click="logout">Odhlásiť</b-link>
            </div>
            <a 
              :href="
              $route.params.type == 'gamecraft' ||
              $route.params.type == 'gamecamp'||
              $route.params.type == 'levelup-your-skills' ? 'https://hemisfera.sk/' + $route.params.type : 'https://hemisfera.sk/'" 
              class="back-button d-flex align-items-center">
              <img src="@/theme/svg/arrow-back.svg" />
              <span>Späť na hemisfera.sk</span>
            </a>
          </b-navbar-nav>
        </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return{
      isMoxios: localStorage.getItem('isMoxios') == 'true'
    }
  },

  computed: {
    ...mapGetters('wAuth', ['user']),
  },
  methods: {
    async logout() {
      await this.$store.dispatch('wAuth/logout')
      await this.$store.commit('order/reseted')
      this.$router.push({ name: 'ProductTypeSelect' })
    },
    toggleMocks() {
      const isMocked = localStorage.getItem('isMoxios') == 'true'
      this.isMoxios = !isMocked
      localStorage.setItem('isMoxios', !isMocked)
    }
  },
}
</script>

<style lang="sass" scoped>
.user
  a
    font-size: 14px

.fixed-top
  z-index: 9999 !important

.back-button
  text-decoration: none
  height: 40px
  padding: 0 10px
  background: #7ECF6B
  border: 1px solid rgba(126, 207, 107, 0.4)
  box-sizing: border-box
  border-radius: 4px
  color: white
  font-weight: 800

  img
    padding-right: 10px
</style>